@import "../mixins";

.root {
  max-width: var(--small-content-width);
  margin: 0 auto;
  padding: 0 var(--gutter);

  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.help {
  margin: 40px 0;
}

.helpItem {
  position: relative;
  padding: 30px 0 0 115px;
  margin-top: 40px;
  box-sizing: border-box;

  @include for-phone-only() {
    padding-left: 0;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 84px;
    height: 1px;
    background-color: #D4D4D4;
  }

  ul {
    margin: 0;
    padding-left: 15px;
  }
}

.helpTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;

  @include for-phone-only() {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }
}

.helpIcon {
  position: absolute;
  top: 40px;
  left: 25px;

  @include for-phone-only() {
    position: static;
    display: inline-block;
    vertical-align: middle;
  }
}

.contact {
  margin: 15px 0;

  p {
    margin: 0;
  }
}
