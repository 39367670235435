@import "../mixins";

.root {
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
}

.tag {
  @extend %reset-button;
  cursor: pointer;
  background-color: #FFF;
  font-size: 13px;
  line-height: 30px;
  padding: 0 20px;
  margin: 5px;
  border-radius: 100px;
  transition: background-color .3s ease-in-out;

  &:hover:not(.active) {
    background-color: #E5E5E5;
  }

  &.active {
    background-color: #EECFCF;
  }
}
