@import "../mixins";

@mixin hamburger-line {
  width: 100%;
  height: 3px;
  background-color: black;
  position: absolute;
  left: 0;
}

@mixin for-mobile-header {
  @media (max-width: 900px) { @content; }
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
}

.content {
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 10px var(--gutter);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 42px;
  font-weight: 700;
}

.logo {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.hamburgerButton {
  display: none;
  cursor: pointer;
  @extend %reset-button;

  @include for-mobile-header() {
    display: block;
  }
}

.socialsNonMobile {
  @media (min-width: 1200px) {
    margin: 0 10px;
  }

  @include for-mobile-header() {
    display: none;
  }
}

.socialsMobile {
  display: none;
  margin-right: 10px;

  @include for-mobile-header() {
    display: block;
  }
}

.hamburgerBox {
  display: block;
  width: 30px;
  height: 24px;
  position: relative;
  z-index: 10;
}

.hamburgerInner {
  @include hamburger-line;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.2s ease-in-out;

  .active & {
    background-color: transparent;
  }

  &::before,
  &::after {
    @include hamburger-line;
    content: "";
    transition: transform 0.2s ease-in-out;
  }

  &::before {
    top: -10px;

    .active & {
      transform: translateY(10px) rotate(45deg);
    }
  }

  &::after {
    top: 10px;

    .active & {
      transform: translateY(-10px) rotate(-45deg);
    }
  }
}

.navigationItems {
  display: flex;
  align-items: center;

  @include for-mobile-header() {
    display: none;
    position: fixed;
    padding: 50px 10px;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #DBDBDB;

    &.active {
      display: block;
    }
  }
}

.menu {
  list-style: none;
  display: flex;
  padding: 0;

  @include for-mobile-header() {
    display: block;
  }
}

.link {
  padding: 10px 15px;
  text-decoration: none;
  color: #000;
  transition: all .3s ease-in-out;

  &:hover:not(.active) {
    color: #7B7B7B;
  }

  &.active {
    background: #F4F4F4;
  }

  @media (min-width: 1000px) and (max-width: 1100px)  {
    margin: 0 5px;
  }

  @media (min-width: 1100px)  {
    margin: 0 10px;
  }
}

.donate {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 18px;
  line-height: 1;
  font-size: 10px;
  text-decoration: none;
  color: #FFF;
  background-color: #DE0303;
  border-radius: 5px;
  transition: all .3s ease-in-out;

  &.donateNonMobile {

    @include for-mobile-header() {
      display: none;
    }

    @media (min-width: 850px) {
      margin: 0 10px;
    }

    @media (min-width: 1200px) {
      margin: 0 20px;
    }
  }

  &.donateMobile {
    display: none;

    @include for-mobile-header() {
      display: flex;
      margin-right: 20px;
    }
  }

  &:hover {
    background-color: #9A0101;
    color: #FFF;
  }
}

.langSelector {
  display: flex;

  @include for-mobile-header() {
    margin-left: 12px;
  }
}

.langItem {
  text-decoration: none;
  color: #000;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  transition: all .3s ease-in-out;
  margin-left: 10px;
  border: 1px solid transparent;

  &:hover:not(.active) {
    background-color: #DADADA;
    border-color: #DADADA;
    color: #7B7B7B;
  }

  &.active {
    border-color: #A5A3A3;
  }

  @include for-mobile-header() {
    background-color: transparent;

    &.active {
      color: #000;
      border-color: #FFF;
    }
  }
}
