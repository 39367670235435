@import "../mixins";

.root {
  background-color: #5B5B5B;
  padding: 20px var(--gutter) 35px;

  @include for-phone-only() {
    text-align: center;
  }
}

.inner {
  max-width: var(--content-width);
  margin: 0 auto;

  @include for-phone-only() {
    padding: 0 40px;
  }
}

.top {
  display: flex;
  align-items: center;

  @include for-phone-only() {
    display: block;
  }
}

.description {
  color: #FFF;
  max-width: 550px;
  font-size: 12px;
  line-height: 15px;
  margin-left: 40px;

  @include for-phone-only() {
    margin-left: 0;
  }
}

.copyright {
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  margin-top: 20px;
  color: #9B9B9B;
}
