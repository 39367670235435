@import "../mixins";

.root {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12px;
}

.button {
  @extend %reset-button;
  cursor: pointer;
  color: #DE0303;
  border: 1px solid #DE0303;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 35px;
  width: 50px;
  transition: all .3s ease-in-out;
  box-sizing: border-box;
  margin-right: 15px;

  .isGalleryShare & {
    margin: 0 8px;
    color: #FFF;
    border: 1px solid #FFF;
  }

  path, stroke {
    transition: fill .2s ease-in-out;
  }

  &:hover {
    border-color: #9A0101;
    color: #9A0101;

    .isGalleryShare & {
      border-color: darken(#FFF, 20%);
      color: darken(#FFF, 20%);
    }
  }
}

.copy {
  display: flex;
  align-items: center;
  padding: 0 15px;
  text-transform: uppercase;
  width: auto;

  svg {
    margin-right: 9px;
  }
}

.nativeShare {
  &:hover {
    path {
      fill: none;
    }
  }
}
