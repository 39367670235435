@import "../mixins";

.h1 {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  font-weight: 700;
  max-width: 650px;
  margin: 26px auto;
}

.h3 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: #DE0303;
  margin: 0;

  &.dark {
    color: #000;
  }
}

.h4 {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
  color: #000;
  margin: 0;

  @include for-phone-only() {
    font-size: 20px;
  }
}
