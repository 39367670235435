@import "../mixins";

.root {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
}

.button {
  @extend %reset-button;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  margin: 5px;
  cursor: pointer;

  &.isActive {
    border: 1px solid #A5A3A3;
  }
}
