@import "./mixins";

html {
  --content-width: 1120px;
  --small-content-width: 810px;
  --gutter: 30px;

  @include for-phone-only() {
    --gutter: 15px;
  }
}

body, html {
  height: 100%;
}

body {
  font-family: 'Mulish', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  transition: color .3s ease-in-out;

  &:hover {
    color: #7B7B7B;
  }
}
