.root {
  max-width: var(--small-content-width);
  margin: 0 auto;
  padding: 0 var(--gutter);
}

.content {
  margin: 60px 0;
}

.item:not(:last-child):after {
  content: "";
  display: block;
  width: 84px;
  height: 1px;
  background-color: #D4D4D4;
  margin: 30px 0;
}

.itemContent {
  font-size: 14px;
  line-height: 24px;

  ul {
    padding-left: 20px;
  }
}
