@import "../mixins";

.root {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  padding: 0;

  &.large {
    zoom: 1.1;
    justify-content: flex-start;
  }
}

.social {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  margin: 0 12px;

  path {
    transition: fill .3s ease-in-out;

    .large & {
      fill: #000;
    }

    .topBar & {
      fill: #DE0303;
    }
  }

  &:hover {
    path {
      fill: #B4B4B4;

      .large & {
        fill: #7B7B7B;
      }

      .topBar & {
        fill: #9A0101;
      }
    }
  }
}
