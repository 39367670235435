@import "../mixins";

.root {
  max-width: 520px;
  margin: 20px auto 30px;
}

.option {
  display: flex;
  align-items: center;
  font-size: 18px;
}
