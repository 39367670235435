@import "../mixins";

.root {
  max-width: var(--content-width);
  padding: 0 var(--gutter);
  margin: 50px auto;

  @include for-phone-only() {
    margin: 0 auto 20px;
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.listItem {
  width: 100px;
  margin: 15px 40px;

  @include for-phone-only() {
    margin: 15px;
  }
}

.logo {
  display: block;
  max-width: 100%;
}

.support {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./army.png");
  max-width: 529px;
  margin: 0 auto;
  height: 94px;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover:after {
    opacity: 0.2;
  }
}

.supportText {
  font-weight: 700;
  font-size: 30px;
  line-height: 1.2;
  margin-left: 40px;
  color: #FFF;

  @include for-phone-only() {
    font-size: 24px;
  }
}
