@import "../mixins";

.root {
  background: #F4F4F4;
  padding: 0 var(--gutter);
}

.headingWrapper {
  padding: 0 var(--gutter);
}

.content {
  padding: 25px 0 50px;
  max-width: var(--content-width);
  margin: 0 auto;
}

.cards {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10px -16px 0;

  @media (max-width: 795px) {
    justify-content: center;
  }

  @media (max-width: 1180px) {
    max-width: (352px + 32px) * 2;
    margin-left: auto;
    margin-right: auto;
  }
}

.loader {
  position: absolute;
  background-color: #F4F4F470;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding-top: 140px;

  > * {
    zoom: 1.4;
  }
}

.card {
  display: flex;
  flex-direction: column;
  flex: 0 1 352px;
  margin: 16px;
  background-color: #fff;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  @include for-phone-only() {
    margin-left: 0;
    margin-right: 0;
  }
}

.poster {
  max-width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.cardInner {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cardDate {
  font-size: 12px;
  line-height: 24px;
  color: #A5A3A3;
}

.message {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.3;
}

.shareMenu {
  margin-top: 10px;
}
