.headingWrapper {
  padding: 0 var(--gutter);
}

.root {
  background: #F4F4F4;
  padding: 0 var(--gutter);
}

.content {
  padding: 35px 0 50px;
  max-width: var(--content-width);
  margin: 0 auto;
}

.loader {
  display: flex;
  justify-content: center;
  height: 855px;
  box-sizing: border-box;
  padding-top: 60px;
}

.masonryGrid {
  display: flex;
  margin-left: -20px; /* gutter size offset */
  margin-top: 25px;
  width: auto;
}

.masonryGridColumn {
  padding-left: 20px;
  background-clip: padding-box;
}

.item {
  position: relative;
  margin-bottom: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:hover {
    .shareMenu {
      opacity: 1;
    }

    &:after {
      opacity: 0.6;
    }
  }
}

.itemImg {
  display: block;
  max-width: 100%;
}

.shareMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 1;

  &:hover & {
    opacity: 100%;

    &:before {
      opacity: 0.6;
    }
  }
}

.footer {
  white-space: pre-wrap;
  text-align: center;

  a {
    color: #DE0303;
    transition: color .3s ease-in-out;

    &:hover {
      color: darken(#DE0303, 20%);
    }
  }
}
